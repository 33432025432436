import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Header from "../components/header"
import Seo from "../components/seo"

// markup
const HiyoriteiPage = () => {
  return (
    <div>
      <Seo title="ACCESS - 小幡楼へのアクセス" />
      <main id="wrap">
        <Header />
        <article className="main-col" role="main">
          <div className="main-wrap">
            <div className="key-visual">
              <div className="main-img">
                <div className="img">
                  <StaticImage 
                    layout="fullWidth"
                    alt="アクセスメイン画像"
                    src="../images/access/mv.jpg"
                    className="pc"
                  />
                  <StaticImage 
                    layout="fullWidth"
                    alt="アクセスメイン画像"
                    src="../images/access/mv-sp.jpg"
                    className="sp"
                  />
                  <div className="logo-text sp">
                    <h1>ACCESS</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="c-contents__map">
            <div className="c-contents__map-wrap">
              <h2 className="pc">ACCESS</h2>
              <p className="sans">〒998-0037 <br className="sp" />山形県酒田市日吉町2丁目9−37</p>
              <a href="https://goo.gl/maps/YJeR6nPtPZeSawhQ7"><span className="sans">GOOGLE MAP</span></a>
            </div>
            <div className="google-map">
              <iframe src="https://www.google.com/maps/d/embed?mid=1HlCxC42KpucFUUeroZG9kKfzl9XUcmIK&hl=ja"></iframe>
            </div>
            <div className="c-contents__map-access">
              <p>
              航空便をご利用の場合<br />
              羽田空港 ─｛所要時間：約1時間｝─ 庄内空港（空港よりタクシーで20分程）<br /><br />
              新幹線をご利用の場合<br />
              ●東京駅 ─｛上越新幹線｝─ 新潟駅 ─｛羽越本線：特急いなほ｝─ 酒田駅（駅よりタクシーで6分程）<br />
              ●東京駅 ─｛山形新幹線｝─ 新庄駅 ─｛陸羽西線｝─ 酒田駅（駅よりタクシーで6分程）<br /><br />
              車をご利用の場合<br />
              日本海東北自動車道　酒田ICから車で約17分<br /><br />
              バスをご利用の場合<br />
              酒田駅より庄内交通バス5分、寿町下車、徒歩5分
              </p>
            </div>
            <div className="c-contents__map-parking">
              <h2>【駐車場のご案内】</h2>
              <div className="img">
                <StaticImage 
                  layout="fullWidth"
                  alt="駐車場のご案内画像"
                  src="../images/access/img01.jpg"
                  class="pc"
                />
                <StaticImage 
                  layout="fullWidth"
                  alt="駐車場のご案内画像"
                  src="../images/access/img01-sp.jpg"
                  class="sp"
                />
              </div>
              <div className="parking-point sp">
                <p>
                ① 小幡楼駐車場<br />
                ② 日和山公園第二駐車場<br />
                ③ 日和山公園第一駐車場<br />
                ④ 日和山公園第三駐車場<br />
                ⑤ 山王クラブ駐車場
                </p>
              </div>
            </div>
          </section>

          <footer>
            <div className="c-footer__wrap">
              <ul>
                <li><Link className="menu-item store-info" to="/">日和亭 小幡楼</Link></li>
                <li><Link className="menu-item store-info" to="/bakerycafe/">ヒヨリベーカリー＆カフェ</Link></li>
                <li><Link className="menu-item store-info" to="/hiyoritei/">日和亭</Link></li>
                <li><Link className="menu-item store-info" to="/access/">アクセス</Link></li>
                <li><Link className="menu-item store-info" to="/history/">割烹小幡の歴史</Link></li>
              </ul>
            </div>
            <small className="sans">&copy; 2021 obatarou</small>
          </footer>
        </article>
      </main>
    </div>
  )
}

export default HiyoriteiPage
